<template>
  <div
    class="fixed w-full z-modalsContent top-0 bottom-0 modalBg flex justify-center"
  >
    <div class="bg-lightest w-full max-w-768 h-full flex flex-col justify-between overflow-y-scroll">
      <div id="reader" class="w-full" />
      <div class="h-full flex flex-col">
        <div class="flex basis-40 shrink-0">
          <div class="basis-[58px] shrink-0">
            <button class="btn btn--secondary" @click="changeCamera(-1)">
              <img src="/icons/arrow-left.svg" class="w-16">
            </button>
          </div>
          <div
            class="basis-full text-center bg-lighter whitespace-nowrap overflow-hidden type-xs-medium flex items-center justify-center"
          >
            <img src="/icons/camera-viewfinder.svg" class="w-16 inline-block mr-8">
            {{ currentCamera }}
          </div>
          <div class="basis-[58px] shrink-0">
            <button class="btn btn--secondary" @click="changeCamera(1)">
              <img src="/icons/arrow-right.svg" class="w-16">
            </button>
          </div>
        </div>

        <div class="pb-16 basis-full overflow-auto">
          <BarcodeResults
            v-for="result in results"
            :key="result"
            :code="result"
            @add-part-no="addPartNo"
            @delete-part-no="deletePartNo"
          />
        </div>

        <div
          class="basis-40 shrink-0 flex"
        >
          <button class="btn btn--secondary basis-1/2" @click="attemptClose">
            {{ $t('modal.generic.abort') }}
          </button>
          <button
            class="btn btn--confirm basis-1/2"
            :disabled="!results.length"
            @click="addAndClose"
          >
            {{ $t('barcode.add') }}
          </button>
        </div>
      </div>
      <div v-if="showPopUp" class="fixed inset-0 modalBg flex items-center justify-center">
        <div class="bg-lightest flex flex-col justify-between">
          <div class="p-24 type-sm-medium">
            {{ $t('barcode.popup.text') }}
          </div>
          <div
            class="basis-40 shrink-0 flex"
          >
            <button class="btn btn--secondary basis-1/2" @click="close">
              {{ $t('barcode.popup.close') }}
            </button>
            <button
              class="btn btn--confirm basis-1/2"
              @click="showPopUp = false"
            >
              {{ $t('barcode.popup.continue') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Html5Qrcode } from 'html5-qrcode';
import { QrcodeResult } from 'html5-qrcode/core';
import BarcodeResults from '~/components/fastOrder/BarcodeResults.vue';
import { useUiStore } from '~/store/ui';

const { isMobile } = useUiStore();

const emit = defineEmits<{
  (e: 'addProducts', payload: AddProducts[]): void;
  (e: 'close'): void;
}>();

const preferedCamera = useCookie('preferedCamera', { maxAge: 3600 * 24 * 182, path: '/' });

const results = ref<string[]>([]);

type Device = {id: string, label: string}

const config = {
  fps: 10,
  qrbox: function(viewfinderWidth, viewfinderHeight) {
    let boxWidth = Math.floor(0.50 * Math.min(viewfinderWidth, viewfinderHeight)); // 85% of its container
    let boxHeight = Math.floor(1 * Math.min(viewfinderWidth, viewfinderHeight)); // 85% of its container

    return isMobile ? {
      width: boxWidth,
      height: boxHeight,
    } : {
      width: 350,
      height: 250,
    };
  },
  aspectRatio: 1 };

const scanner = ref<any>(null);
const cameras = ref<Device[]>([]);
const selectedCamera = ref('');

const showPopUp = ref(false);

onMounted(()=> {
  setTimeout(()=> {
    Html5Qrcode.getCameras()
      .then((devices: Device[]) => {
        if (devices.length) {
          scanner.value = new Html5Qrcode('reader');
          cameras.value = devices;
          selectedCamera.value = devices[0].id;
          if (preferedCamera.value && preferedCamera.value !== '') {
            const pcindex = devices.findIndex((f) => f.id === preferedCamera.value);
            if (pcindex > -1) {
              selectedCamera.value = preferedCamera.value;
            }
          }
          startCamera();
        }
      })
      .catch(err => {
        console.log(typeof err);
        console.log(err);
      });
  }, 200);
});

const currentCamera = computed(()=> {
  const current = cameras.value.find((f) => f.id === selectedCamera.value);
  if (current) {
    return current.label;
  }
  return 'No camera selected';
});

const changeCamera = (dir: number) => {
  let newIndex = cameras.value.findIndex((f) => f.id === selectedCamera.value) + dir;
  if (newIndex < 0) {
    newIndex = cameras.value.length - 1;
  }
  if (newIndex > cameras.value.length -1) {
    newIndex = 0;
  }
  selectedCamera.value = cameras.value[newIndex].id;
  preferedCamera.value = cameras.value[newIndex].id;
  scanner.value.stop().then(() => {
    startCamera();
  });
};

const startCamera = () => {
  scanner.value.start(
    selectedCamera.value,
    config,
    (decodedText: string, decodedResult: QrcodeResult) => {
      if (!results.value.includes(decodedText)) {
        console.log(decodedResult);
        results.value.unshift(decodedText);
      }
    }
  );
};

type AddProducts = { partNo: string, name: string, brand: string, quantity: number };

const addProducts = ref<AddProducts[]>([]);
const addPartNo = (payload: AddProducts) => {
  const exists = addProducts.value.findIndex((f) => f.partNo === payload.partNo);
  if (exists === -1) {
    addProducts.value.unshift(payload);
  } else {
    addProducts.value[exists].quantity = payload.quantity;
  }
};

const deletePartNo = ({ partNo, code }: { partNo: string, code: string }) => {
  const existsPartNo = addProducts.value.findIndex((f) => f.partNo === partNo);
  if (existsPartNo > -1) {
    addProducts.value.splice(existsPartNo, 1);
  }

  if (results.value.includes(code)) {
    const existsCode = results.value.findIndex((f) => f === code);
    if (existsCode > -1) {
      results.value.splice(existsCode, 1);
    }
  }
};

const addAndClose = () => {
  if (addProducts.value.length) {
    emit('addProducts', addProducts.value);
  } else {
    emit('close');
  }
};

const close = () => {
  emit('close');
};

const attemptClose = () => {
  if (addProducts.value.length) {
    showPopUp.value = true;
  } else {
    close();
  }
};

</script>

<style lang="postcss">

#reader {
  @screen mobOnly {
    height: 100%; /* I guess it can be anything as long as the barcode fits */
    overflow: hidden;
    position: relative;

    video {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
    }
  }
}
</style>
