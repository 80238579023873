<template>
  <div class="flex">
    <button class="btn btn--secondary btn--minus btn--md !border-r-0 !border-t !border-b !border-l !border-light !bg-lightest touchOnly" @click="change(-1)" />
    <div>
      <input
        :id="id"
        v-model="value"
        type="number"
        class="miniNumber"
        min="1"
        :tabindex="(index * 10) +1"
        v-bind="attr"
        @keydown.enter="emit('enter')"
        @blur="emit('blurred')"
        @keyup="emit('change')"
        @change="emit('change')"
      >
    </div>
    <button class="btn btn--secondary btn--plus btn--md !border-r !border-t !border-b !border-l-0 !border-light !bg-lightest touchOnly" @click="change(1)" />
  </div>
</template>

<script setup lang="ts">
const value = defineModel<number>();
const props = defineProps<{
  index: number,
  id: string,
  attr?: {
    [key: string]: string | number;
  };
}>();

const emit = defineEmits<{
  (e: 'enter'): void;
  (e: 'blurred'): void;
  (e: 'change'): void;
}>();

const change = (dir: number) => {
  const newNumber = value.value + dir;
  if (props.attr?.max && newNumber > props.attr.max) {
    return;
  }

  if (newNumber >= 1) {
    value.value = newNumber;
  }
  emit('change');
};

</script>

<style scoped lang="postcss">
.miniNumber {
  @apply border border-light leading-single inline-block p-8 h-36 hover:border-dark focus:border-darkest rounded-0;
}

@media (pointer: coarse) { /*  touch */
  .miniNumber {

    @apply w-48 text-center;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply hidden;
    }
  }
}

@media (pointer: fine) {
  .miniNumber {
    @apply w-64;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply opacity-100;
    }
  }
  .touchOnly {
    @apply hidden;
  }
}

</style>
