<template>
  <div class="flex justify-start items-center border-b border-light py-8 px-16 type-sm-medium">
    <div class="w-32 h-32 mr-16">
      <div
        v-if="found"
        class="barcodeIcon will-change-transform w-32 h-32 bg-brightGreen rounded-full flex items-center justify-center"
      >
        <img src="/icons/check.svg" class="w-16 h-16">
      </div>

      <div v-else class="barcodeIcon will-change-transform w-32 h-32 bg-light rounded-full flex items-center justify-center">
        <img src="/icons/barcode.svg" class="w-16 h-16">
      </div>
    </div>
    <div class="pt-4 flex justify-between items-center w-full">
      <div>{{ showName }}</div>
      <!--        <div class="h-16 mt-8">
        <LoadingIcon />
      </div>-->
      <div class="flex items-center">
        <TouchNumber
          v-if="found"
          :id="`${code}-discount`"
          v-model="quantity"
          :index="code"
          :attr="{ max: 100, class: 'allBlurredInput' }"
          @change="updateItemList"
        />
        <div class="cursor-pointer p-4 ml-8" @click="deleteItem">
          <img
            src="/icons/trashcan.svg"
            class="w-16 h-16 ml-8"
          >
        </div>
      </div>
    </div>
    <audio id="addedSound">
      <source src="/sounds/start.mp3" type="audio/mpeg">
    </audio>
  </div>
</template>

<script setup lang="ts">
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import { AlgoliaSearchPayloadRequestOptions } from '~/constants/types/algolia';
import TouchNumber from '~/components/form-elements/TouchNumber.vue';
const { getFromAlgolia } = useAlgoliaFetch();
const { $t } = useNuxtApp();

const props = defineProps<{
  code: string,
}>();

const emit = defineEmits<{
  (e: 'addPartNo', payload: { partNo: string, name: string, brand: string, quantity: number }): void;
  (e: 'deletePartNo', payload: { partNo: string, code: string }): void;
}>();

const result = ref<{
  partNo: string,
  name: string,
  brand: string,
}>({
  partNo: '',
  name: '',
  brand: '',
});

const quantity = ref(1);

onMounted(() => {
  setTimeout(() => {
    const audio = document.getElementById('addedSound');
    if (audio) {
      try {
        audio.play().catch(e => {
          console.log('Error playing sound:', e);
        });
      } catch (error) {
        console.log('Caught an error:', error);
      }
    }
  }, 200);
  lookupBarcode(props.code);
});

const found = ref(false);
const failed = ref(false);
const foundName = ref('');

const lookupBarcode = async(ean:string) => {
  console.log('lookupBarcode');
  // This work, have to add parametricsFilter.ean to searchable attributes
  const requestOptions = {
    query: ean,
    typoTolerance: true,
    restrictSearchableAttributes: 'eanCode',
  } as AlgoliaSearchPayloadRequestOptions;
  console.log('requestOptions', requestOptions);
  const res = await getFromAlgolia(requestOptions);
  console.log('algola res', res);
  if (res && res.hits && res.hits[0]) {
    const partNo = res.hits[0].partNo;
    result.value = {
      partNo,
      name: res.hits[0].name,
      brand: res.hits[0].brand.name,
    };

    updateItemList();
    found.value = true;
    foundName.value = res.hits[0].brand.name + ' - ' + res.hits[0].name;
  } else {
    failed.value = true;
  }
};

const updateItemList = () => {
  emit('addPartNo', {
    ...result.value,
    quantity: quantity.value,
  });
};

const deleteItem = () => {
  emit('deletePartNo', { partNo: result.value.partNo, code: props.code });
};

const showName = computed(()=> {
  if (found.value) {
    return foundName.value;
  }
  if (failed.value) {
    return $t('barcode.notFound') + props.code;
  }
  return props.code;
});

</script>

<style scoped lang="postcss">
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.barcodeIcon {
  animation-duration: .7s;
  animation-name: added;
}
@keyframes added {
  from {
    transform: scale(0);
  }
  75% {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}
</style>
